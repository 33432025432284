/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Staff from '@/components/Organization/Staff';
import Dep from '@/components/Organization/Dep';
export default {
  name: 'fileList',
  components: {
    Staff: Staff,
    Dep: Dep
  },
  props: {},
  data: function data() {
    return {
      searchvalue: '',
      personList: [{
        personName: '王五',
        personPsot: '部门负责人',
        id: '004'
      }, {
        personName: '李雷',
        personPsot: '部门主管',
        id: '005'
      }],
      depList: [{
        depName: '董事长',
        personNum: 1,
        id: 1
      }, {
        depName: '总经办',
        personNum: 5,
        id: 2
      }, {
        depName: '营销中心',
        personNum: 36,
        id: 3
      }, {
        depName: '财务部',
        personNum: 54,
        id: 4
      }, {
        depName: '软件技术部',
        personNum: 50,
        id: 5
      }, {
        depName: '硬件中心',
        personNum: 50,
        id: 6
      }],
      depPath: [],
      companyName: '威尔数据',
      currentPath: '/fileList'
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.back, false);
    }
  },
  destroyed: function destroyed() {
    window.removeEventListener('popstate', this.back, false);
  },
  methods: {
    back: function back() {
      this.$router.push({
        path: '/'
      });
    },
    ExamineList: function ExamineList() {
      this.$router.push({
        path: '/ExamineList' // query: {
        //     personid: personid,
        // },

      });
    },
    //点击公司架构下的一级部门
    depdetail: function depdetail(item) {
      // 跳转组织架构
      this.$router.push({
        path: '/organizationList',
        query: {
          depid: item.id,
          //选择的部门编号
          depName: item.depName,
          //选择的部门名称
          companyName: '威尔数据' //公司名称

        }
      });
    }
  }
};